import request from '@/utils/request'


// 查询客服聊天记录列表
export function listNotice(query) {
  return request({
    url: '/customer/notice/list',
    method: 'get',
    params: query
  })
}

// 查询客服聊天记录分页
export function pageNotice(query) {
  return request({
    url: '/customer/notice/page',
    method: 'get',
    params: query
  })
}

// 查询客服聊天记录详细
export function getNotice(data) {
  return request({
    url: '/customer/notice/detail',
    method: 'get',
    params: data
  })
}

// 新增客服聊天记录
export function addNotice(data) {
  return request({
    url: '/customer/notice/add',
    method: 'post',
    data: data
  })
}

// 修改客服聊天记录
export function updateNotice(data) {
  return request({
    url: '/customer/notice/edit',
    method: 'post',
    data: data
  })
}

// 删除客服聊天记录
export function delNotice(data) {
  return request({
    url: '/customer/notice/delete',
    method: 'post',
    data: data
  })
}
